.NotificationPanel {
  position: fixed;
  bottom: 0;
  right: 10px;
  max-height: calc(100vh - 5.5rem); /* 5rem is 1rem more than the header height */
  max-width: 60vw;
  z-index: 1000;
  background: var(--background);
  box-shadow: 1px 1px 3px #333;
  border-radius: 3px 3px 0 0;
  border-top: 1px solid #333;
  border-left: 1px solid #333;

  .header {
    background: var(--header-background);
    padding: 2px;
    cursor: pointer;
    &:hover {
      background: var(--header-background-hover);
    }

    .leftPartOfHeader {
      color: var(--header-text-colour);
      font-size: 1.2rem;
      padding-left: 2px;
    }
  }

  .closeAll {
    color: var(--header-text-colour);
    font-size: 0.75rem;
    margin: calc(3px + 0.3rem);
    cursor: pointer;
    user-select: none;
    float: right;
    :hover {
      font-weight: 700;
    }
  }


  .notificationList {
    max-height: 0;
    overflow-y: auto;
    min-width: 100%;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

    &.expanded {
      max-height: 90vh;
      transition: max-height 0.5s ease-in-out;
    }
    > div {
      margin: 3px;
    }
  }

}
