.Loading {
  width: 100%;
  height: 0.5em;
  background-color: #99999999;
  margin: 0.5em 0;
  overflow: hidden;
  position: relative;
}

.Loading > div {
  background-color: var(--accent);
  animation: load 4s infinite ease-in-out;
  height: 0.5em;
  position: absolute;
}

@keyframes load {
  0% {
    left: 0;
    right: 100%;
  }

  25% {
    left: 0;
    right: 0;
  }

  50% {
    left: 100%;
    right: 0;
  }

  75% {
    left: 0;
    right: 0;
  }

  100% {
    left: 0;
    right: 100%;
  }
}
