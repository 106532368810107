.InlineButtonWrapper {
  height: 1em;
  overflow-x: visible;
  > .Button {
    padding: 2px 6px;
    box-sizing: content-box;
  }
}

.Button {
  pointer-events: auto;
  padding: 8px 12px;
  display: inline-flex;
  background: #e7e7e7;
  white-space: nowrap;
  line-height: 1.4;
  user-select: none;
  border: 1px solid #ccc;
  justify-content: space-around;
  font-family: inherit;
  .buttonContent {
    display: flex;
    align-items: center;
  }
  &:enabled {
    cursor: pointer;
    &:hover {
      color: #333;
      background-color: darken(#e7e7e7, 10%);
      border-color: darken(#ccc, 10%);
    }
    &:active {
      transform: scale(.95);
    }
  }

  &.blue {
    color: white;
    border-color: rgb(0, 140, 186);
    background: rgb(0, 140, 186);
    &:hover:enabled {
      color: white;
      border-color: darken(rgb(0, 140, 186), 10%);
      background: darken(rgb(0, 140, 186), 10%);
    }
  }

  &.red {
    color: white;
    border-color: #d43f3a;
    background: #d9534f;
    &:hover:enabled {
      color: white;
      border-color: darken(#d43f3a, 10%);
      background: darken(#d9534f, 10%);
    }
  }

  &.green {
    color: white;
    border-color: #4cae4c;
    background: #5cb85c;
    &:hover:enabled {
      color: white;
      border-color: darken(#4cae4c, 10%);
      background: darken(#5cb85c, 10%);
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: #aaa;
    background: #e7e7e7;
    border: 1px solid #ccc;
  }
}
