.NotificationRow {
  min-height: 2em;
  border-radius: 2px;
  padding: 0.3em;
  margin: 2px;
  margin-top: 3px;
  min-width: 25em;

  display: flex;
  justify-content: space-between;
  align-items: stretch;

  pre { // https://stackoverflow.com/questions/248011/how-do-i-wrap-text-in-a-pre-tag
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    font-family: 'Open Sans', sans-serif;
    margin: 0;
  }

  &.standard {
    color: #004085;
    background-color: #cce5ff;
    border: 1px solid #00408555;
  }
  &.error {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #721c2455;
  }
  &.warning {
    color: #856404;
    background-color: #f1e4b9;
    border: 1px solid #85640455;
  }
  &.success {
    color: #155724;
    background-color: #d4edda;
    border: 1px solid #15572455;
  }

  .dismiss {
    float: right;
    height: 100%;
    display: flex;
    padding-left: 2px;
    cursor: pointer;
    :hover {
      color: black;
    }
  }
}