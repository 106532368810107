.MainHeader {
  > header {
    z-index: 1000;
    background: var(--background);
    width: 100%;
    position: fixed;
    height: 4.5rem;
    top: 0;
    left: 0;
  
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    border-bottom: 2px solid var(--table-border-color);
    box-sizing: content-box;

    > h1 {
      margin: 0;
      margin-bottom: 1rem;
      padding-left: 1rem;
      vertical-align: middle;
      line-height: 1em;
      height: 1em;
      cursor: pointer;
    }
    > nav {
      display: flex;
      height: 100%;
      position: relative;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  .NavLink, .NavClick {
    display: flex;
    align-items: flex-end;
    height: 100%;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
    color: var(--text-colour);
    padding: 1rem;
    background: transparent;
    transition: background 0.2s ease-out;
    line-height: 1em;
    > span {
      font-family: 'Roboto Slab', serif;
      &::after {
        content: '';
        display: block;
        margin: auto;
        width: 0;
        transition: width 0.2s ease-out;
        height: 2px;
        background: var(--accent);
        position: relative;
        top: 5px;
      }
    }
    &:hover {
      background: #00000009;
    }
    &:hover, &.active {
      > span::after {
        width: 100%;
      }
    }
  }

  .spacer {
    height: 4.5rem;
  }
}

@media (max-width: 52rem) {
  .MainHeader > header {
    flex-direction: column;
    justify-content: start;
    align-items: center;

    h1 {
      margin: 0;
    }
    nav {
      height: 50%;
      width: 100%;
      justify-content: space-around;
    }

    .NavLink, .NavClick {
      padding-bottom: 0.2rem
    }
  }
}
