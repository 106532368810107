.ErrorPage {
  width: 100%;
  height: 100%;
  overflow: auto;

  > div {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  > small {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0.5rem;
    text-align: center;

    &.versionPresent {
      bottom: 3rem;
    }
  }

  h1 {
    font-size: 10rem;
    margin: 0;
  }

  h2 {
    font-size: 3rem;
    margin: 0;
  }
}
