@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&family=Roboto+Slab:wght@300;400;500;700&display=swap');
* {
  box-sizing: border-box;
}

#root {
  display: grid;
  min-height: 100vh;
  grid-template-rows: 4.5rem 1fr auto;
  grid-template-columns: 100%;
}

html {
  overflow-y: scroll;
}

input {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 1rem;
}

body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --header-background: #f9f9f9;
  --header-background-hover: #efefef;
  --header-text-colour: black;
  --header-text-colour-hover: grey;
  --header-small-colour: grey;
  --table-header-font-weight: 600;
  --background: white;
  --background-hover: #efefef;
  --text-colour: black;
  --small-colour: grey;
  --accent: #137ab4;
  --table-border-color: #ddd;

  background-color: var(--background);
  color: var(--text-colour);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto Slab', serif;
  font-weight: normal;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

small {
  color: var(--small-colour);
  font-size: 1em;
}

.margin {
  margin: 1rem;
}

.marginHorizontal {
  margin: 0 1rem;
}

.marginVertical {
  margin: 1rem 0;
}

.padding {
  padding: 1rem;
}

.fullWidth {
  width: 100%;
}

.paddedGrid {
  display: grid;
  grid-gap: 1rem;
  padding: 1rem;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gridRow, .gridRowStart, .gridRowEnd, .gridRowCenter {
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: column;
  align-items: center;
  .gridRowStart, .gridRowEnd, .gridRowCenter {
    grid-auto-columns: min-content;
  }
  &.gridRowStart {
    justify-content: start;
  }
  &.gridRowEnd {
    justify-content: end;
  }
  &.gridRowCenter {
    justify-content: center;
  }
}

.errorText {
  color: brown;
  font-style: italic;
}

.centered {
  text-align: center;
}

.iconButton {
  cursor: pointer;
  user-select: none;
  &:hover {
    color: #333;
  }
}

.stackTraceSection {
  overflow-x: auto;
  display: grid;
  // The 4rem is space for the floating button
  grid-template-columns: auto 4rem;
  > pre {
    margin: 0;
    padding: 0.5rem;
  }
  .wrapText {
    white-space: pre-wrap;
  }
}