.Login {
  > .container {
    display: block;
    margin: 5rem auto;
    width: 400px;

    border: 2px solid #333;
    border-radius: 2px;

    form {
      padding: 1rem;

      h3 {
        margin: 0 0 1rem 0;
        text-align: center;
      }

      div:not(.Loading) {
        margin-bottom: 1rem;
      }

      input, label {
        display: block;
      }

      input {
        width: 100%;
      }

      .submit {
        width: max-content;
        margin: auto;
      }
    }
  }
}
